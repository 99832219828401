<template>
  <div>
    <div class="font-bold text-lg mb-2 mt-5">
      TOTAL LABELED: {{ totalLabeled }}
    </div>
    <div class="font-bold text-lg mb-2 mt-5">{{ business }} CALL</div>
    <div class="font-bold text-lg mb-2 mt-5">CALL ID: {{ transcriptId }}</div>
    <div class="font-bold mb-1 underline">Transcript</div>
    <div v-for="trans in transcript" :key="trans.id" class="mb-1">
      <span class="font-bold">{{ trans.speaker }}: </span> {{ trans.text }}
    </div>
    <b-overlay :show="checkSelect">
      <template #overlay>
        <div v-if="!loading" class="font-bold text-lg">
          <div>{{ selected }}?</div>
          <b-button-group class="mt-2">
            <b-button @click="selectYes()"> Yes </b-button>
            <b-button class="ml-2" @click="selectNo()"> No </b-button>
          </b-button-group>
        </div>
        <div v-else><b-spinner class="align-middle"></b-spinner></div>
      </template>
      <b-button-group class="flex flex-wrap mt-3 pl-1 border mb-20">
        <b-button
          v-for="(selection, index) in selections"
          :key="index"
          variant="outline-primary"
          class="mb-1 mr-1 rounded-lg"
          @click="areYouSure(selection)"
        >
          {{ selection }}
        </b-button>
      </b-button-group>
    </b-overlay>
  </div>
</template>
  
  <script>
import { BButtonGroup, BButton, BOverlay } from "bootstrap-vue";
export default {
  components: { BButtonGroup, BButton, BOverlay },
  data() {
    return {
      loading: false,
      selected: "",
      business: "",
      checkSelect: false,
      transcript: [],
      transcriptId: "",
      selections: [],
      totalLabeled: 0,
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
    };
  },

  created() {
    this.getCall();
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
  },

  destroyed() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", this.contentWidth);
  },
  methods: {
    selectYes() {
      this.loading = true;

      this.$AuthAxios.instance
        .post("/api-access/filing-view/label-data/", {
          params: { callId: this.transcriptId, outcome: this.selected },
        })
        .then(() => {
          this.getCall();
          this.selected = "";
        });
    },
    selectNo() {
      this.selected = "";
      this.checkSelect = false;
    },
    areYouSure(selection) {
      this.selected = selection;

      this.checkSelect = true;
    },
    getCall() {
      this.$AuthAxios.instance
        .get("/api-access/filing-view/label-data/", {})
        .then((res) => {
          this.checkSelect = false;
          this.loading = false;
          this.business = res.data.business;
          this.transcript = res.data.transcript;
          this.transcriptId = res.data.transcript_id;
          this.selections = res.data.selections;
          this.totalLabeled = res.data.total_labeled;
        });
    },
  },
};
</script>
  